<template>
  <div>
    <section class="section section-secondary jpadding">
      <div class="jcard jcard-main jcard-nohover">
        <div class="mb-5 text-center">
          <h3>CREATE WATCH</h3>
          <p>Create a new watch item and add it to an existing user.</p>
        </div>

        <div class="grid-2">
          <div class="jcard-mini" @click="$bvModal.show('watch-models-list-modal')">
            <div class="d-flex align-items-center">
              <p>
                <small><i class="fal fa-watch"></i> WATCH MODEL</small><br />{{ model.name }}
              </p>
              <i class="ml-auto fal fa-chevron-down"></i>
            </div>
          </div>

          <div class="jcard-mini" @click="$bvModal.show('users-list-modal')">
            <div class="d-flex align-items-center">
              <p>
                <small><i class="fal fa-user"></i> CUSTOMER</small><br />{{ customer.firstName + " " + customer.lastName
                }}
              </p>
              <i class="ml-auto fal fa-chevron-down"></i>
            </div>
          </div>
        </div>

        <div class="mt-5">
          <div class="form-row">
            <div class="col-md">
              <div class="form-group">
                <label for="serial"><i class="fal fa-barcode-alt"></i> Serial Number</label>
                <input type="text" v-bind:class="{
                  'form-control': true,
                  'is-invalid': !validSerial(watch.serial) && bluredSerialNumber
                }" v-on:blur="bluredSerialNumber = true" v-model="watch.serial" />
                <div class="invalid-feedback">
                  Please enter a valid serial number. Min. 10 digits.
                </div>
              </div>
            </div>

            <div class="col-md">
              <div class="form-group">
                <label for="status"><i class="fal fa-exclamation-circle"></i> Status</label>
                <b-form-select v-model="watch.status" :options="watchStatusOptions"></b-form-select>
                <div class="invalid-feedback">
                  Please select a valid status.
                </div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <!-- <div class="col-md">
              <div class="form-group">
                <label for="purchasedOn"><i class="fal fa-calendar-alt"></i> Purchase Date</label>
                <input type="date" v-bind:class="{
                  'form-control': true,
                  'is-invalid': !validDate(watch.purchasedOn) && bluredPurchasedOn
                }" v-on:blur="bluredPurchasedOn = true" v-model="watch.purchasedOn" />
                <div class="invalid-feedback">
                  Please enter when the watch was purhcased.
                </div>
              </div>
            </div> -->

            <!-- <div class="col-md">
              <div class="form-group">
                <label for="purchasedAt"><i class="fal fa-globe-europe"></i> Purchase Location</label>
                <input type="text" v-bind:class="{
                  'form-control': true,
                  'is-invalid': !validInput(watch.purchasedAt) && bluredPurchasedAt
                }" v-on:blur="bluredPurchasedAt = true" v-model="watch.purchasedAt" />
                <div class="invalid-feedback">
                  Please enter where the watch was purchased.
                </div>
              </div>
            </div> -->
          </div>

          <!-- alert -->
          <b-alert v-if="showInputError" show variant="danger"><i class="fad fa-exclamation-circle"></i>
            {{ validationMsg }}
          </b-alert>

          <div class="btn-container text-center mt-4 mb-4">
            <button class="jbtn jbtn-blue" value="Add" @click="createWatch">
              Create
            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- ----- watch models list modal --------------------------------- -->
    <b-modal :hide-header="true" :hide-footer="true" id="watch-models-list-modal" ref="watch-models-list-modal" centered
      title="BootstrapVue">
      <div class="jdialog-top">
        <div class="close-container">
          <h3>
            <strong>Select Watch Model</strong>
          </h3>
          <span @click="$bvModal.hide('watch-models-list-modal')" class="ml-auto jclose"><i
              class="fa fa-times "></i></span>
        </div>
        <p>
          <small>Please select a Duke watch model first from the list below.</small>
        </p>
      </div>
      <!-- Search -->
      <SearchAndFilters class="d-flex mb-3" :searchList=watchModelsList></SearchAndFilters>
      <div class="jdialog-main grid-2">
        <!-- LIST LOOP -->
        <div v-for="(model, index) in watchModelsList" :key="model.id" @click="selectModel(index)" class="jcard-mini">
          <div class="d-flex align-items-center">
            <h5>{{ model.name }}</h5>
          </div>
          <hr />
          <!-- <div class="form-row">
            <div class="col-md">
              <p><small>Model ID</small><br />{{ model.id.substring(0, 8) }}</p>
            </div>
            <div class="col-md">
              <p><small>Price</small><br />{{ model.price }}€</p>
            </div>
          </div> -->
          <p><small>Price</small><br />{{ model.price }}€</p>
        </div>
      </div>

      <!-- dialog bottom -->
      <div class="jdialog-bottom with-cancel">
        <button @click="$bvModal.hide('watch-models-list-modal')" class="jbtn jbtn-sm jbtn-red"><i
            class="fa fa-times"></i> Cancel</button>
        <!-- <button @click="updateWatchModel" class="jbtn jbtn-sm">
          <i class="fa fa-check"></i> Next
        </button> -->
      </div>
    </b-modal>

    <!-- ----- users list modal --------------------------------- -->
    <b-modal :hide-header="true" :hide-footer="true" id="users-list-modal" ref="users-list-modal" centered
      title="BootstrapVue">
      <div class="jdialog-top">
        <div class="close-container">
          <h3>
            <strong>Select a Customer</strong>
          </h3>
          <span @click="$bvModal.hide('users-list-modal')" class="ml-auto jclose"><i class="fa fa-times "></i></span>
        </div>
        <p>
          <small>Please select a Duke Customer who should receive a new watch.</small>
        </p>
      </div>
      <!-- Search -->
      <SearchAndFilters class="d-flex mb-3" :searchList=usersList></SearchAndFilters>
      <div class="jdialog-main grid-2">
        <!-- LIST LOOP -->
        <div v-for="(user, index) in usersList" :key="user.id" @click="selectUser(index)" class="jcard-mini">
          <div class="d-flex align-items-center">
            <h6>{{ user.firstName + " " + user.lastName }}</h6>
          </div>
          <hr />
          <p>
            <small><i class="fal fa-envelope"></i> EMAIL</small><br />{{ user.email }}
          </p>
        </div>
      </div>

      <!-- dialog bottom -->
      <div class="jdialog-bottom with-cancel">
        <button @click="$bvModal.hide('users-list-modal')" class="jbtn jbtn-sm jbtn-red"><i class="fa fa-times"></i>
          Cancel</button>
        <!-- <button @click="updateWatchModel" class="jbtn jbtn-sm">
          <i class="fa fa-check"></i> Next
        </button> -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import SearchAndFilters from "@/components/general/SearchAndFilters";
import toast from "@/assets/js/toast";
import firebase from "@/firebase/firebaseInit";
import C from "@/const";
const db = firebase.db;

export default {
  name: "CreateWatch",
  components: {
    SearchAndFilters,
  },
  data() {
    return {
      title: "Create Watch",

      watchModelsList: [],
      usersList: [],

      watchStatusOptions: C.getFormattedOptionsArray(C.STATUS.WATCH_STRINGS),

      isLoading: false,
      editMode: false,
      //   userId: "",
      //   watchId: "",
      //   modelId: "",

      //form error alert
      showInputError: false,
      validationMsg: "",

      //form button
      isSaving: false,

      //watch data form validation
      valid: false,
      bluredSerialNumber: false,
      bluredPurchasedOn: false,
      bluredPurchasedAt: false,

      model: {
        id: "",
        name: "Please select a Watch Model"
      },
      customer: {
        id: "",
        firstName: "Please select a",
        lastName: "Customer"
      },
      watch: C.WATCH,
    };
  },
  methods: {
    //load all watch models list
    allWatchModels: function () {
      db.collection(C.COLLECTION.WATCH_CATALOG).onSnapshot(querySnapshot => {
        this.watchModelsList.splice(0);
        querySnapshot.forEach(doc => {
          const docdata = doc.data();
          docdata.id = doc.id;
          this.watchModelsList.push(docdata);
        });
      });
    },
    //load all users list
    allUsers: function () {
      db.collection(C.COLLECTION.USERS).onSnapshot(querySnapshot => {
        this.usersList.splice(0);
        querySnapshot.forEach(doc => {
          const user = doc.data();
          user.id = doc.id;
          user.name = user.firstName + " " + user.lastName;

          this.usersList.push(user);
        });
      });
    },

    selectModel(index) {
      //set selected model
      this.model = this.watchModelsList[index];
      this.watch.modelId = this.model.id;
      // hide dialog
      this.$refs["watch-models-list-modal"].hide();
    },
    selectUser(index) {
      //set selected user
      this.customer = this.usersList[index];
      this.watch.userId = this.customer.id;
      // hide dialog
      this.$refs["users-list-modal"].hide();
    },
    createWatch() {
      if (!this.validate()) {
        console.log("validation FAILED" );
        return;
      }
      var docRef;
      if (this.editMode) docRef = db.collection(C.COLLECTION.WATCHES).doc(this.watchModelId);
      else docRef = db.collection(C.COLLECTION.WATCHES).doc();
      docRef
        .set(this.watch, { merge: true })
        .then(() => {
          if (this.editMode) toast.success("Watch edited.");
          else {
            this.$router.replace({ name: "WatchesList" });
            toast.success("New watch added.");
          } 

        })
        .catch(error => {
          if (this.editMode) toast.error("Couldn't edit watch. " + error.message);
          else toast.error("Couldn't create new watch. " + error.message);
        });
    },
    validate() {
      this.bluredSerialNumber = true;
      if (!this.validSerial(this.watch.serial)) {
        this.valid = false;
        return false;
      }
      // this.bluredPurchasedOn = true;
      // if (!this.validDate(this.watch.purchasedOn)) {
      //   this.valid = false;
      //   return false;
      // }
      // this.bluredPurchasedAt = true;
      // if (!this.validInput(this.watch.purchasedAt)) {
      //   this.valid = false;
      //   return false;
      // }

      if (!this.validInput(this.watch.modelId)) {
        this.valid = false;
        toast.error("Please select a Watch Model.");
        return false;
      }

      if (!this.validInput(this.watch.userId)) {
        this.valid = false;
        toast.error("Please select a Customer.");
        return false;
      }

      this.valid = true;
      return true;
    },
    validInput(input) {
      return input.trim().length > 0;
    },
    validSerial(input) {
      return input.trim().length > 9;
    },
    validDate(input) {
      return input != null && input != "";
    },
  },
  mounted() {
    this.allWatchModels();
    this.allUsers();
  }
};
</script>

<style lang="scss" scoped>
.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: flex-start;
}

.jcard-mini {
  cursor: pointer;
}

.jcard-mini:hover {
  border: 2px solid #edb996;
  color: #a27f68;
}
</style>
